@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
}


@media (max-width: 767px) {
    .orderWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto !important;
        bottom: 0px !important;
    }
    .cardLeft,.cardRight{
        width: 100% !important;
        margin-bottom: 20px;
        justify-content: center;
    }
    .wr,.footer{
        height: auto !important;
    }
    .footer{
        position: absolute;
        bottom: 0px;
    }
}